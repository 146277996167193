import React, { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
const LoadingBarTop = () => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.continuousStart();
  }, []);
  return <LoadingBar color="#ff5c8b" ref={ref} />;
  return null;
};

export default LoadingBarTop;
