import httpClient from '../../services/HttpClient';

export const usePayHook = () => {
  const getInfoPay = async (sessionId) => {
    try {
      const { data } = await httpClient({
        method: 'GET',
        url: `/buy/${sessionId}`
      });

      return { data };
    } catch (err) {
      return { err };
    }
  };

  const methodCall = (method, sessionId = null) => {
    const calls = {
      getInfoPay
    };

    return (calls[method] || calls['getInfoPay'])(sessionId);
  };

  return { methodCall };
};
