import httpClient from '../../services/HttpClient';
import { getUserInformation } from '../../services/TokenHandler';

const { data } = await httpClient({
  method: 'GET',
  url: '/permissions?list=true'
});
data.push({
  //keep this permission to be able to see the secutiry view
  id: 99999,
  url: '/roles',
  method: 'get',
  createdAt: '2022-07-13T22:22:00.550Z',
  updatedAt: '2022-07-13T22:22:00.550Z'
});

const availablePermissions = data;

const SUPER_ADMIN = 'SUPER-ADMIN';
const defaultUserInformation = {
  role: { name: 'No Access', permissions: [0] }
};

const addPermissionsByMethod = (list, method) => {
  const permissions = {};
  list.forEach((item) => {
    if (item.method == method) {
      const key = permissionMapping[item.type];
      if (key) {
        permissions[key] = item.id;
      }
    }

    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        if (child.method == method) {
          const key = permissionMapping[child.type];
          if (key) {
            permissions[key] = child.id;
          }
        }
      });
    }
  });
  return permissions;
};

export const validatePermission = (userPermission, userInfo) => {
  const {
    role: { name, permissions }
  } = userInfo ? JSON.parse(userInfo) : defaultUserInformation;

  if (name === SUPER_ADMIN) return true;
  if (!userPermission) return false;

  const { url, method } = userPermission;

  const hasPermission = permissions.some((permissionInfo) => {
    const match = permissionInfo.url === url && permissionInfo.method === method;

    const childMatch =
      permissionInfo.children &&
      permissionInfo.children.some((child) => child.url === url && child.method === method);

    return match || childMatch;
  });

  return hasPermission;
};

export const getCompanyProducts = async () => {
  const userInfoRaw = getUserInformation();
  if (userInfoRaw == '') return [];
  const userInfo = JSON.parse(userInfoRaw);
  const { data, err } = await httpClient({
    method: 'GET',
    url: `/company/${userInfo.companies[0].id}/products`
  });
  companyProducts = data;
};

export let companyProducts = [];

export const validateCompanyOwnsProduct = (productName) => {
  if (companyProducts.length == 0) {
    console.log('No products');
    return false;
  }
  return companyProducts.some((product) => product.productName === productName);
};

const permissionMapping = {
  FastLane: 'fastlane', //DONE
  CallPass: 'payphone', //DONE
  VIPP: 'vipp', //DONE
  Ventana: 'vnta', //DONE
  Account: 'accounts', //DONE
  Users: 'users', //DONE
  Products: 'products', //DONE
  'IVR Configuration': 'ivr-config',
  VoIP: 'voip', //DONE
  Calls: 'calls', //DONE
  Revenues: 'revenue', //MISSING FER
  Payments: 'payments', //MISSING FER
  Security: 'security'
};

export const getUrlPermission = (currentLocation, type) => {
  const createDuplicatePermission = {
    ...addPermissionsByMethod(availablePermissions, 'post')
    /*     fastlane: 4,
    payphone: 6,
    vipp: 181,
    vnta: 200,
    accounts: 37,
    users: 30,
    products: 176,
  'ivr-config': 42,
    voip: 48,
    calls: 54,
    revenue: 53,
    payments: 52,
    security: 99999 */
  };
  const editPermission = {
    ...addPermissionsByMethod(availablePermissions, 'put')
    /*     fastlane: 5,
    payphone: 7,
    vipp: 181,
    vnta: 200,
    accounts: 38,
    users: 32,
    products: 176,
    'ivr-config': 57,
    voip: 49,
    calls: 54,
    revenue: 53,
    payments: 52,
    security: 99999 */
  };

  const changeStatusPermission = {
    ...addPermissionsByMethod(availablePermissions, 'patch')

    /*     fastlane: 8,
    payphone: 9,
    vipp: 181,
    vnta: 200,
    accounts: 39,
    users: 135,
    products: 175,
    'ivr-config': 42,
    voip: 50,
    calls: 54,
    revenue: 53,
    payments: 52,
    security: 99999 */
  };

  const permissionType = {
    createDuplicate: createDuplicatePermission[currentLocation],
    edit: editPermission[currentLocation],
    changeStatus: changeStatusPermission[currentLocation]
  };

  return (
    availablePermissions.find((item) => item.id == permissionType[type]) ||
    availablePermissions
      .flatMap((item) => item.children || [])
      .find((child) => child.id == permissionType[type])
  );
};

export const userPermissions = (url, method) => {
  const findPermission = (permissionsList, url, method) => {
    for (const permission of permissionsList) {
      if (permission.method === method && permission.url === url) {
        return permission;
      }
      if (permission.children) {
        const foundInChildren = findPermission(permission.children, url, method);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  };

  return findPermission(availablePermissions, url, method);
};
