import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const AccessNumberContext = createContext();

const AccessNumberProvider = ({ children }) => {
  const [accessNumber, setAccessNumber] = useState();
  const [nodePosition, setNodePosition] = useState();

  const setAccessNumberInfo = (accessNumber) => {
    setAccessNumber(accessNumber ? `+ ${accessNumber}` : null);
  };

  const setNodePositionInfo = (nodeInfo) => {
    setNodePosition(nodeInfo);
  };

  return (
    <AccessNumberContext.Provider
      value={{ accessNumber, setAccessNumberInfo, setNodePositionInfo, nodePosition }}
    >
      {children}
    </AccessNumberContext.Provider>
  );
};

AccessNumberProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export { AccessNumberProvider, AccessNumberContext };
