import React from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

// import privateRoutes from '../../navigation/routes';

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';

const AppBreadcrumb = () => {
  const breadcrumbs = useSelector((state) => state.breadcrumbs);
  // const getRouteName = (pathname, routes) => {
  //   const currentRoute = routes.find((route) => route.path === pathname);
  //   return currentRoute ? currentRoute.name : false;
  // };

  // const getBreadcrumbs = (location) => {
  //   const breadcrumbs = [];
  //   location.split('/').reduce((prev, curr, index, array) => {
  //     const currentPathname = `${prev}/${curr}`;
  //     const routeName = getRouteName(currentPathname, privateRoutes);
  //     routeName &&
  //       breadcrumbs.push({
  //         pathname: currentPathname,
  //         name: routeName,
  //         active: index + 1 === array.length ? true : false
  //       });
  //     return currentPathname;
  //   });
  //   return breadcrumbs;
  // };

  // const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="ms-2 m-0" style={{ '--cui-breadcrumb-divider': "'>'" }}>
      {/* <CBreadcrumbItem href="/dashboard">Home</CBreadcrumbItem> */}
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumbs.links ? { active: true } : { href: breadcrumbs.link })}
            key={index}
          >
            {breadcrumb.name.startsWith('+')
              ? formatPhoneNumberIntl(breadcrumb.name)
              : breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
