import React from 'react';
import { CSidebar, CSidebarBrand, CSidebarNav, CHeaderToggler } from '@coreui/react';
import SideBarNavigationItems from '../components/sidebar/SideBarNavigationItems';
import HeaderBarNavigation from '../components/header/HeaderBarNavigation';
import CieloAIBrand from '../assets/images/svg/cieloai-new-logo.svg';
import navigation from '../navigation/navigationItems';
import { useSelector, useDispatch } from 'react-redux';

import AppContent from './AppContent';

const DefaultLayout = () => {
  const dispatch = useDispatch();
  let sidebarShow = useSelector((state) => state.sidebarShow);

  window.onresize = () => dispatch({ type: 'set', sidebarShow: window.innerWidth > 768 });

  return (
    <>
      <CSidebar className="Dashboard-Sidebar" position="fixed" visible={sidebarShow}>
        <CSidebarBrand>
          <div className="Brand-container">
            <img src={CieloAIBrand} alt="Cielo Brand" style={{ maxWidth: '172px' }} />
            <CHeaderToggler
              className="ps-1 d-flex d-lg-none close-menu"
              onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
            >
              X
            </CHeaderToggler>
          </div>
        </CSidebarBrand>
        <CSidebarNav>
          <SideBarNavigationItems items={navigation} />
        </CSidebarNav>
      </CSidebar>
      <div className="wrapper d-flex flex-column min-vh-100 DashboardContent">
        <HeaderBarNavigation />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
