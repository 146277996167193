import { useState } from 'react';

export const useToastHook = () => {
  const [toast, setToast] = useState({ show: false, message: '', color: '' });

  const handleToastState = (message = '', color = 'success') => {
    setToast((prevState) => {
      return { show: !prevState.show, message, color };
    });
  };

  return { toast, handleToastState };
};
