import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUserInformation } from '../../services/TokenHandler';
import { getCompanyProducts } from '../../utils/permissions/validatePermission';

import {
  validatePermission,
  userPermissions,
  validateCompanyOwnsProduct
} from '../../utils/permissions/validatePermission';
import { useState, useEffect } from 'react';

const SideBarNavigationItems = ({ items }) => {
  const location = useLocation();
  const userInfo = getUserInformation();
  const {
    role: { name: nameRol }
  } = JSON.parse(userInfo);
  const navLink = (name, icon) => {
    return (
      <>
        {icon && icon}
        {name && name}
      </>
    );
  };

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink
          })}
        key={name}
        {...rest}
      >
        {navLink(name, icon)}
      </Component>
    );
  };

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={name}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items
          ?.filter((item) => item.permission === 1)
          .map((item, index) => navItem(item, index))}
      </Component>
    );
  };
  const permission = (permissionType) => {
    const defaultPermission = +true;
    const permissionName = {
      Dashboard: defaultPermission,
      'Call Flow': +validatePermission(userPermissions('/access-number', 'get'), userInfo),
      'Pricing Rules':
        +validatePermission(userPermissions('/pricing-rule', 'get'), userInfo) ||
        +validatePermission(userPermissions('/payphone', 'get'), userInfo),
      FastLane:
        +validatePermission(userPermissions('/pricing-rule', 'get'), userInfo) &&
        +validateCompanyOwnsProduct('FastLane'),
      CallPass:
        +validatePermission(userPermissions('/payphone', 'get'), userInfo) &&
        +validateCompanyOwnsProduct('CallPass'),
      'VIP Phone Support':
        +validatePermission(userPermissions('/vipp', 'get'), userInfo) &&
        +validateCompanyOwnsProduct('Vip Phone Support'),
      Ventana:
        +validatePermission(userPermissions('/vnta', 'get'), userInfo) &&
        +validateCompanyOwnsProduct('Ventana'),
      Reports:
        +validatePermission(userPermissions('/reports/payments', 'get'), userInfo) &&
        +validatePermission(userPermissions('/reports/revenues', 'get'), userInfo) &&
        +validatePermission(userPermissions('/reports/calls', 'get'), userInfo),
      Calls: +validatePermission(userPermissions('/reports/calls', 'get'), userInfo),
      Payments: +validatePermission(userPermissions('/reports/payments', 'get'), userInfo),
      Revenue: +validatePermission(userPermissions('/reports/revenues', 'get'), userInfo),
      System:
        +validatePermission(userPermissions('/company', 'get'), userInfo) ||
        +validatePermission(userPermissions('/user', 'get'), userInfo),
      Settings:
        +validatePermission(userPermissions('/company', 'get'), userInfo) ||
        +validatePermission(userPermissions('/user', 'get'), userInfo) ||
        +validatePermission(userPermissions('/roles', 'get'), userInfo),
      Accounts: +(
        validatePermission(userPermissions('/company', 'get'), userInfo) &&
        nameRol === 'SUPER-ADMIN'
      ),
      Products: +(
        validatePermission(userPermissions('/company/products', 'post'), userInfo) &&
        nameRol === 'SUPER-ADMIN'
      ),
      Users: +validatePermission(userPermissions('/user', 'get'), userInfo),
      Roles: +validatePermission(userPermissions('/roles', 'get'), userInfo),
      Interconnection:
        +validatePermission(userPermissions('/api-connections', 'get'), userInfo) ||
        +validatePermission(userPermissions('/node-assigns', 'get'), userInfo) ||
        +validatePermission(userPermissions('/trunks', 'get'), userInfo),
      API: +validatePermission(userPermissions('/api-connections', 'get'), userInfo),

      Documentation: +validatePermission(userPermissions('/documentation', 'get'), userInfo),
      VoIP: +validatePermission(userPermissions('/trunks', 'get'), userInfo),

      'IVR Configuration': +validatePermission(userPermissions('/node-assigns', 'get'), userInfo),
      Support: defaultPermission,
      'Page Not Found': defaultPermission
    };

    return permissionName[permissionType];
  };

  const addNavigationChildPermission = (item) => {
    const itemChilds = item.items;

    const childWithPermissions = itemChilds.map((child) => ({
      ...child,
      permission: permission(child.name)
    }));

    return { ...item, items: childWithPermissions, permission: permission(item.name) };
  };

  const addNavigationItemPermission = () => {
    if (!items || items.length === 0) {
      return [];
    }

    return items.map((item) => {
      return item.items
        ? addNavigationChildPermission(item)
        : { ...item, permission: permission(item.name) };
    });
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCompanyProducts().then(() => {
      setLoading(false);
    });
  }, []);
  const renderNavigationItems = () => {
    if (items) {
      return addNavigationItemPermission()
        .filter((item) => item.permission === 1)
        .map((item, index) => {
          return item.items ? navGroup(item, index) : navItem(item, index);
        });
    }

    return <></>;
  };

  return <>{items && !loading ? renderNavigationItems() : <></>}</>;
};

SideBarNavigationItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default SideBarNavigationItems;
