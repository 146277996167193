/* eslint-disable react/prop-types */
import React from 'react';
export const DashboardI = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66669 8.23343C1.66669 7.43881 2.04445 6.69151 2.68431 6.22033L10 0.833313L17.3157 6.22033C17.9556 6.69151 18.3334 7.43881 18.3334 8.23343V17.0833C18.3334 18.2339 17.4006 19.1666 16.25 19.1666H3.75002C2.59943 19.1666 1.66669 18.2339 1.66669 17.0833V8.23343Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CallFlow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66665 15.8334H3.33331V5.83344M3.33331 0.833435V5.83344M3.33331 5.83344H6.66665M13.8704 2.5001H12.5C11.5795 2.5001 10.8333 3.24629 10.8333 4.16677V7.5001C10.8333 8.42058 11.5795 9.16677 12.5 9.16677H15C15.9205 9.16677 16.6666 8.42058 16.6666 7.5001V5.64099C16.6666 5.27448 16.5458 4.9182 16.3229 4.62728L15.1933 3.15306C14.8779 2.74146 14.3889 2.5001 13.8704 2.5001ZM13.8704 12.5001H12.5C11.5795 12.5001 10.8333 13.2463 10.8333 14.1668V17.5001C10.8333 18.4206 11.5795 19.1668 12.5 19.1668H15C15.9205 19.1668 16.6666 18.4206 16.6666 17.5001V15.641C16.6666 15.2745 16.5458 14.9182 16.3229 14.6273L15.1933 13.1531C14.8779 12.7415 14.3889 12.5001 13.8704 12.5001Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Pricing = () => (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3754 5.91387C10.3754 5.91387 9.82834 3.26327 5.99997 3.26327M5.99997 3.26327C2.1716 3.26327 1.15555 4.87002 1.07776 5.97679C0.99997 7.08356 0.999964 9.58356 5.99997 9.58356M5.99997 3.26327V0.833557M5.99997 3.26327L5.99997 9.58356M5.99997 9.58356C11 9.58356 11 12.5002 11 12.9169C11 13.3336 10.9222 16.5163 5.99997 16.5163M5.99997 9.58356L5.99997 16.5163M5.99997 16.5163C1.07778 16.5163 1.07778 13.8657 1.07778 13.8657M5.99997 16.5163V19.1669"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Reports = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 16.667V10.8337C17.5 8.99273 16.0076 7.50035 14.1667 7.50035H12.4342C11.7439 7.50035 11.1842 6.9407 11.1842 6.25035V4.16701C11.1842 2.32606 9.69183 0.833679 7.85088 0.833679H5M17.5 16.667C17.5 18.0477 16.3807 19.167 15 19.167H5C3.61929 19.167 2.5 18.0477 2.5 16.667V3.33368C2.5 1.95297 3.61929 0.833679 5 0.833679M17.5 16.667V11.0709C17.5 9.30313 16.8755 7.59214 15.7368 6.23992L13.4318 3.50266C12.0067 1.81043 9.90727 0.833679 7.69494 0.833679H5"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
  </svg>
);

export const Products = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3334 6.20432L18.6976 6.85994C18.928 6.73191 19.0743 6.4923 19.083 6.22882C19.0916 5.96535 18.9612 5.71669 18.7396 5.57389L18.3334 6.20432ZM10 0.833923L10.4063 0.203496C10.1589 0.0440658 9.84113 0.0440658 9.59374 0.203496L10 0.833923ZM1.66669 6.20432L1.26041 5.57389C1.03882 5.71669 0.908473 5.96535 0.917088 6.22882C0.925702 6.4923 1.07201 6.73191 1.30246 6.85994L1.66669 6.20432ZM2.05256 13.5241C1.69737 13.311 1.23668 13.4262 1.02357 13.7814C0.810456 14.1366 0.92563 14.5973 1.28082 14.8104L2.05256 13.5241ZM10 19.1673L9.61415 19.8104C9.85166 19.9529 10.1484 19.9529 10.3859 19.8104L10 19.1673ZM18.7192 14.8104C19.0744 14.5973 19.1896 14.1366 18.9765 13.7814C18.7634 13.4262 18.3027 13.311 17.9475 13.5241L18.7192 14.8104ZM18.7396 5.57389L10.4063 0.203496L9.59374 1.46435L17.9271 6.83474L18.7396 5.57389ZM9.59374 0.203496L1.26041 5.57389L2.07296 6.83474L10.4063 1.46435L9.59374 0.203496ZM1.30246 6.85994L9.63579 11.4895L10.3643 10.1783L2.03092 5.5487L1.30246 6.85994ZM10.3643 11.4895L18.6976 6.85994L17.9691 5.5487L9.63579 10.1783L10.3643 11.4895ZM1.28082 14.8104L9.61415 19.8104L10.3859 18.5241L2.05256 13.5241L1.28082 14.8104ZM10.3859 19.8104L18.7192 14.8104L17.9475 13.5241L9.61415 18.5241L10.3859 19.8104ZM2.41669 14.5839V6.25059H0.916687V14.5839H2.41669ZM19.0834 14.5839V6.25059H17.5834V14.5839H19.0834ZM10.75 18.3339V10.8339H9.25002V18.3339H10.75Z"
      fill="#F7F7F7"
    />
  </svg>
);
export const Interconnection = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.96999 15.2204L9.91627 12.2741L12.8625 15.2204L9.91627 18.1667L6.96999 15.2204Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
    <path
      d="M1.66669 9.91709L4.61297 6.97081L7.55924 9.91709L4.61297 12.8634L1.66669 9.91709Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
    <path
      d="M12.2733 9.91709L15.2196 6.97081L18.1658 9.91709L15.2196 12.8634L12.2733 9.91709Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
    <path
      d="M6.96999 4.61379L9.91627 1.66751L12.8625 4.61379L9.91627 7.56007L6.96999 4.61379Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
  </svg>
);
export const Settings = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 10.0005C13.3333 11.8414 11.8409 13.3338 9.99997 13.3338C8.15902 13.3338 6.66664 11.8414 6.66664 10.0005C6.66664 8.15951 8.15902 6.66713 9.99997 6.66713C11.8409 6.66713 13.3333 8.15951 13.3333 10.0005Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
    <path
      d="M8.57033 1.5529C9.21749 0.472926 10.7825 0.472928 11.4296 1.5529L12.0177 2.53437C12.3952 3.1642 13.1403 3.47284 13.8525 3.29436L14.9624 3.01622C16.1837 2.71018 17.2903 3.81677 16.9842 5.03804L16.7061 6.14792C16.5276 6.86015 16.8362 7.60527 17.4661 7.98269L18.4475 8.57082C19.5275 9.21798 19.5275 10.7829 18.4475 11.4301L17.4661 12.0182C16.8362 12.3956 16.5276 13.1408 16.7061 13.853L16.9842 14.9629C17.2903 16.1841 16.1837 17.2907 14.9624 16.9847L13.8525 16.7066C13.1403 16.5281 12.3952 16.8367 12.0177 17.4665L11.4296 18.448C10.7825 19.528 9.21749 19.528 8.57033 18.448L7.9822 17.4665C7.60479 16.8367 6.85966 16.5281 6.14743 16.7066L5.03755 16.9847C3.81628 17.2907 2.70969 16.1841 3.01574 14.9629L3.29387 13.853C3.47235 13.1408 3.16371 12.3956 2.53388 12.0182L1.55241 11.4301C0.472438 10.7829 0.47244 9.21798 1.55241 8.57082L2.53388 7.98269C3.16371 7.60527 3.47235 6.86014 3.29387 6.14792L3.01574 5.03804C2.70969 3.81677 3.81629 2.71018 5.03755 3.01623L6.14743 3.29436C6.85966 3.47284 7.60479 3.1642 7.9822 2.53437L8.57033 1.5529Z"
      stroke="#F7F7F7"
      fill="none"
      strokeWidth="1.5"
    />
  </svg>
);
export const Support = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4818 3.51819C20.0616 7.098 20.0616 12.902 16.4818 16.4818C12.902 20.0616 7.09797 20.0616 3.51817 16.4818M16.4818 3.51819C12.902 -0.0616175 7.09797 -0.0616175 3.51817 3.51819M16.4818 3.51819L12.9463 7.05373M3.51817 16.4818C-0.0616385 12.902 -0.0616385 7.098 3.51817 3.51819M3.51817 16.4818L7.0537 12.9462M3.51817 3.51819L6.75907 6.75908M12.9463 12.9463L16.1872 16.1872M14.1666 10C14.1666 12.3012 12.3012 14.1667 9.99998 14.1667C7.6988 14.1667 5.83331 12.3012 5.83331 10C5.83331 7.69881 7.6988 5.83333 9.99998 5.83333C12.3012 5.83333 14.1666 7.69881 14.1666 10Z"
      stroke="#F7F7F7"
      strokeWidth="1.5"
      fill="none"
    />
  </svg>
);
export const Timer = ({ w = 20, h = 20, c = '#fff' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.75 5.8335C10.75 5.41928 10.4142 5.0835 9.99998 5.0835C9.58577 5.0835 9.24998 5.41928 9.24998 5.8335H10.75ZM9.99998 10.8335H9.24998C9.24998 11.0324 9.329 11.2232 9.46965 11.3638L9.99998 10.8335ZM11.553 13.4472C11.8459 13.7401 12.3208 13.7401 12.6136 13.4472C12.9065 13.1543 12.9065 12.6794 12.6136 12.3865L11.553 13.4472ZM18.4166 10.0002C18.4166 14.6486 14.6484 18.4168 9.99998 18.4168V19.9168C15.4768 19.9168 19.9166 15.477 19.9166 10.0002H18.4166ZM9.99998 18.4168C5.35158 18.4168 1.58331 14.6486 1.58331 10.0002H0.083313C0.083313 15.477 4.52316 19.9168 9.99998 19.9168V18.4168ZM1.58331 10.0002C1.58331 5.35177 5.35158 1.5835 9.99998 1.5835V0.0834961C4.52316 0.0834961 0.083313 4.52334 0.083313 10.0002H1.58331ZM9.99998 1.5835C14.6484 1.5835 18.4166 5.35177 18.4166 10.0002H19.9166C19.9166 4.52334 15.4768 0.0834961 9.99998 0.0834961V1.5835ZM9.24998 5.8335V10.8335H10.75V5.8335H9.24998ZM9.46965 11.3638L11.553 13.4472L12.6136 12.3865L10.5303 10.3032L9.46965 11.3638Z"
      fill={c}
    />
  </svg>
);
export const Price = ({ w = 20, h = 20, c = '#fff' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.68795 4.99984V14.9998M7.18795 12.6515L7.92045 13.2007C8.89628 13.9332 10.4788 13.9332 11.4554 13.2007C12.4321 12.4682 12.4321 11.2815 11.4554 10.549C10.9679 10.1823 10.3279 9.99984 9.68795 9.99984C9.08378 9.99984 8.47961 9.8165 8.01878 9.45067C7.09711 8.71817 7.09711 7.5315 8.01878 6.799C8.94045 6.0665 10.4354 6.0665 11.3571 6.799L11.7029 7.074M18.0213 9.99984C18.0213 14.6022 14.2903 18.3332 9.68795 18.3332C5.08557 18.3332 1.35461 14.6022 1.35461 9.99984C1.35461 5.39746 5.08557 1.6665 9.68795 1.6665C14.2903 1.6665 18.0213 5.39746 18.0213 9.99984Z"
      stroke={c}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Vipp = ({ w = 25, h = 25, c = '#fff' }) => (
  <svg width={w} height={h} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M120.617 28.3557C123.354 21.7981 132.645 21.7981 135.382 28.3557L158.865 84.6105C159.959 87.2312 162.361 89.0755 165.175 89.4563L227.067 97.8331C234.002 98.7718 236.474 107.534 231.052 111.959L183.262 150.957C180.9 152.885 179.839 155.988 180.526 158.958L195.805 224.984C197.46 232.132 189.421 237.551 183.416 233.336L132.595 197.667C129.837 195.731 126.162 195.731 123.404 197.667L72.5829 233.336C66.5772 237.551 58.5389 232.133 60.193 224.984L75.4709 158.958C76.1583 155.988 75.0972 152.885 72.7349 150.957L24.9466 111.959C19.5245 107.534 21.9964 98.7718 28.9316 97.8332L90.8238 89.4563C93.6381 89.0754 96.0394 87.2312 97.1334 84.6105L120.617 28.3557Z"
      stroke={c}
      fill="none"
      strokeWidth="16"
      strokeMiterlimit="3.3292"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Close = ({ w = 24, h = 24, c = '#fff' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.06 12L17.48 7.57996C17.5537 7.5113 17.6128 7.4285 17.6538 7.3365C17.6948 7.2445 17.7168 7.14518 17.7186 7.04448C17.7204 6.94378 17.7018 6.84375 17.6641 6.75036C17.6264 6.65697 17.5703 6.57214 17.499 6.50092C17.4278 6.4297 17.343 6.37356 17.2496 6.33584C17.1562 6.29811 17.0562 6.27959 16.9555 6.28137C16.8548 6.28314 16.7555 6.30519 16.6635 6.34618C16.5715 6.38717 16.4887 6.44627 16.42 6.51996L12 10.94L7.58 6.51996C7.43782 6.38748 7.24978 6.31535 7.05548 6.31878C6.86118 6.32221 6.67579 6.40092 6.53838 6.53834C6.40096 6.67575 6.32225 6.86113 6.31882 7.05544C6.3154 7.24974 6.38752 7.43778 6.52 7.57996L10.94 12L6.52 16.42C6.37955 16.5606 6.30066 16.7512 6.30066 16.95C6.30066 17.1487 6.37955 17.3393 6.52 17.48C6.66062 17.6204 6.85125 17.6993 7.05 17.6993C7.24875 17.6993 7.43937 17.6204 7.58 17.48L12 13.06L16.42 17.48C16.5606 17.6204 16.7512 17.6993 16.95 17.6993C17.1488 17.6993 17.3394 17.6204 17.48 17.48C17.6204 17.3393 17.6993 17.1487 17.6993 16.95C17.6993 16.7512 17.6204 16.5606 17.48 16.42L13.06 12Z"
      fill={c}
    />
  </svg>
);
export const Clock = ({ w = 24, h = 24, c = '#fff' }) => (
  <svg width={w} height={h} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M136 74.6667C136 70.2484 132.418 66.6667 128 66.6667C123.582 66.6667 120 70.2484 120 74.6667H136ZM128 138.667H120C120 140.788 120.843 142.823 122.343 144.324L128 138.667ZM149.01 170.99C152.134 174.114 157.199 174.114 160.324 170.99C163.448 167.866 163.448 162.801 160.324 159.676L149.01 170.99ZM237.333 128C237.333 188.383 188.383 237.333 128 237.333V253.333C197.22 253.333 253.333 197.22 253.333 128H237.333ZM128 237.333C67.6169 237.333 18.6667 188.383 18.6667 128H2.66666C2.66666 197.22 58.7803 253.333 128 253.333V237.333ZM18.6667 128C18.6667 67.6169 67.6169 18.6667 128 18.6667V2.66666C58.7803 2.66666 2.66666 58.7803 2.66666 128H18.6667ZM128 18.6667C188.383 18.6667 237.333 67.6169 237.333 128H253.333C253.333 58.7803 197.22 2.66666 128 2.66666V18.6667ZM120 74.6667V138.667H136V74.6667H120ZM122.343 144.324L149.01 170.99L160.324 159.676L133.657 133.01L122.343 144.324Z"
      fill={c}
    />
  </svg>
);
