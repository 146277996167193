import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { useUserHook } from '../hooks/AuthHooks/useUserHook';

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [userStatus, setUserStatus] = useState({ isFirstLogin: false });

  const { methodCall } = useUserHook();

  const loginUser = async (email, password) => {
    const { data, status, err } = await methodCall('login', email, password);

    setUserStatus({
      email
    });

    if (err && err.error === 'NEED CHANGE PASSWORD') {
      setUserStatus({
        isFirstLogin: true,
        email: err.email
      });
    }

    return { data, status, err };
  };

  const authenticate2fa = async (email, code) => {
    const { data, status, err } = await methodCall('authenticate2fa', email, code);

    return { data, status, err };
  };

  const resend2faCode = async (email) => {
    const { data, status, err } = await methodCall('resend2faCode', email);

    return { data, status, err };
  };

  const requestNewPassword = async (email) => {
    const { data, status, err } = await methodCall('requestNewPassword', email);

    return { data, status, err };
  };

  const resetPassword = async (newPassword) => {
    const password = newPassword;
    const { data, status, err } = await methodCall('resetPassword', password);

    return { data, status, err };
  };

  const changePassword = async (newPassword) => {
    const email = userStatus.email;
    const password = newPassword;
    const { data, status, err } = await methodCall('changePassword', email, password);

    return { data, status, err };
  };

  const validateJWT = async () => {
    const { data, status, err } = await methodCall('validateJWT');

    return { data, status, err };
  };

  const logout = () => methodCall('logout');

  return (
    <AccountContext.Provider
      value={{
        logout,
        resetPassword,
        changePassword,
        userStatus,
        loginUser,
        authenticate2fa,
        resend2faCode,
        requestNewPassword,
        validateJWT
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export { AccountProvider, AccountContext };
