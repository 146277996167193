import IATACodes from '../../assets/dummyData/IATACodes.json';

export const FastLaneRuleFormat = () => {
  return {
    name: '',
    price: 0,
    minimumWaitTime: 0,
    repeat: 0,
    pricingRuleType: 1,
    timeIntervals: [{ time: 1, numberQueue: 1, price: 1 }],
    fastLaneAgents: [{ ruleValue: 0, operator: '...', fee: 0 }],
    fastLaneCallsVolume: [{ ruleValue: 0, operator: '...', fee: 0 }],
    fastLaneNPAs: [
      {
        country: '',
        phoneArea: '+',
        fee: 0
      }
    ]
  };
};

export const decimalPriceNumber = (price) => {
  const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
  return price ? parseFloat(formatter.format(price)).toFixed(2) : price;
};

export const airPortCodes = IATACodes.map((code) => ({ label: code.code, value: code.code }));
