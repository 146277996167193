import httpClient from '../../services/HttpClient';
import { POST_METHOD } from '../../services/HttpClient/HttpMethods';

export const useUserHook = () => {
  const url = '/auth';

  const logout = async () => {
    localStorage.clear();
  };

  const login = async (email, password) => {
    const loginURL = `${url}/login`;
    const dataObject = {
      userName: email,
      password: password
    };
    try {
      const { data, status } = await httpClient({
        method: POST_METHOD,
        url: loginURL,
        data: dataObject
      });

      return { data, status };
    } catch (err) {
      return { err };
    }
  };

  const authenticate2fa = async (email, code2fa) => {
    const codeURL = `${url}/validate2fa`;
    const dataObject = {
      username: email,
      code: code2fa
    };
    try {
      const { data } = await httpClient({
        method: POST_METHOD,
        url: codeURL,
        data: dataObject
      });

      const {
        jwtAws: { token, refreshToken, accessToken },
        user,
        imageText
      } = data;

      const allUserInfo = {
        ...user,
        imageText
      };

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('idToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userEmail', user.email);
      localStorage.setItem('user', JSON.stringify(allUserInfo));

      return { data };
    } catch (err) {
      return { err };
    }
  };

  const resend2faCode = async (email) => {
    const codeURL = `${url}/resend-code`;
    const dataObject = {
      email
    };

    try {
      const { status } = await httpClient({
        method: POST_METHOD,
        url: codeURL,
        data: dataObject
      });

      return { status };
    } catch (err) {
      return { err };
    }
  };

  const validateJWT = async () => {
    const codeURL = `${url}/validate-jwt`;

    try {
      const { status } = await httpClient({
        method: POST_METHOD,
        url: codeURL
      });

      return { status };
    } catch (err) {
      return { err };
    }
  };

  const requestNewPassword = async (email) => {
    const newPassUrl = `${url}/forgot-password`;
    const dataObject = {
      email
    };

    try {
      const { status } = await httpClient({
        method: POST_METHOD,
        url: newPassUrl,
        data: dataObject
      });

      return { status };
    } catch (err) {
      return { err };
    }
  };

  const changePassword = async (email, password) => {
    const loginURL = `${url}/new-password`;
    const dataObject = {
      userName: email,
      password: password
    };

    try {
      const { data, status } = await httpClient({
        method: POST_METHOD,
        url: loginURL,
        data: dataObject
      });
      return { data, status };
    } catch (err) {
      return { err };
    }
  };

  const resetPassword = async (password) => {
    const loginURL = `${url}/reset-password`;
    const dataObject = {
      password: password
    };

    try {
      const { data, status } = await httpClient({
        method: POST_METHOD,
        url: loginURL,
        data: dataObject
      });
      return { data, status };
    } catch (err) {
      return { err };
    }
  };

  const methodCall = (method, id = null, dataObject = {}, email = '', password = '') => {
    const calls = {
      validateJWT,
      requestNewPassword,
      resend2faCode,
      authenticate2fa,
      logout,
      resetPassword,
      changePassword,
      login
    };

    const methodVar = (calls[method] || calls['get'])(id, dataObject, email, password);

    return methodVar;
  };

  return { methodCall };
};
