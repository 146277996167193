import React, { useContext, useEffect } from 'react';
import { AccountContext } from '../../services/AccountProvider';
import PropTypes from 'prop-types';
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import arrowIcon from '../../assets/images/svg/ArrowDown.svg';
import SVGRenderer from '../../utils/svg/SVGRenderer';
import Buttons from '../buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserInformation } from '../../services/TokenHandler';

const Dropdown = ({ dropdownType, list, image, userInitials }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AccountContext);
  const userInfo = JSON.parse(getUserInformation());

  useEffect(() => {
    const span = document.createElement('span');
    span.id = 'closeMenu';
    document.body.appendChild(span);
    return () => span.remove();
  }, []);

  let navigate = useNavigate();

  const loginOut = () => {
    logout();
    navigate('/login');
  };

  const UserDropdown = () => {
    return (
      <div className="AvatarToggle">
        {image === 'xxxxx.png' ? (
          <CAvatar src={image} size="md" />
        ) : (
          <span className="UserInitials">{userInitials}</span>
        )}
        <div className="AvatarToggle-Information d-none d-lg-flex">
          <span className="AvatarToggle-Information__User">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
          <span className="AvatarToggle-Information__Role">{userInfo.role.name}</span>
        </div>
        <div className="AvatarToggle-Arrow">
          <img src={arrowIcon} alt="arrow icon" />
        </div>
      </div>
    );
  };

  const OptionsDropdown = () => {
    return (
      <div className="CieloTable-Dropdown__Button">
        <SVGRenderer iconName="Options" />
      </div>
    );
  };

  const NotificationDropdown = () => {
    return (
      <div className="NotificationIcon position-relative">
        <img src={image} alt="Notification Icon" />
        <span className="position-absolute rounded-circle NotificationIcon-Dot">
          <span className="visually-hidden">{t('menu.new-alerts')}</span>
        </span>
      </div>
    );
  };

  const renderDropdownToggle = () => {
    switch (dropdownType) {
      case 'user':
        return UserDropdown();
      case 'notification':
        return NotificationDropdown();
      case 'options':
        return OptionsDropdown();
      default:
        break;
    }
  };

  const renderDropdownItems = () => {
    return list.map((item) => {
      if (item.onclick) {
        return (
          <Buttons
            onclick={() => {
              item.onclick();
              document.getElementById('closeMenu').click();
            }}
            key={item.linkName}
            className={
              item.linkName.toLowerCase() === 'deactivate'
                ? 'text-red-cus'
                : item.linkName.toLowerCase() === 'activate'
                ? 'text-blue-cus'
                : ''
            }
            disabled={item.disabled}
          >
            {item.linkName}
          </Buttons>
        );
      }

      if (item.linkName === 'Logout') {
        return (
          <CDropdownItem href={item.linkPath} key={item.linkName} onClick={loginOut}>
            <>{item.linkName}</>
          </CDropdownItem>
        );
      } else {
        return (
          <CDropdownItem
            href={item.linkPath}
            key={item.linkName}
            className={
              item.linkName.toLowerCase() === 'activate'
                ? 'text-blue-cus'
                : item.linkName.toLowerCase() === 'deactivate'
                ? 'text-red-cus'
                : ''
            }
          >
            {item.linkName}
          </CDropdownItem>
        );
      }
    });
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        {renderDropdownToggle()}
      </CDropdownToggle>
      <CDropdownMenu className="py-3" placement="bottom-end">
        {renderDropdownItems()}
      </CDropdownMenu>
    </CDropdown>
  );
};

Dropdown.defaultProps = {
  userDropdown: '',
  image: ''
};

Dropdown.propTypes = {
  dropdownType: PropTypes.string,
  list: PropTypes.array.isRequired,
  image: PropTypes.string,
  userInitials: PropTypes.string
};

export default Dropdown;
