import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import Spinner from '../components/spinner/Spinner';

import {
  getAuthToken,
  getRefreshToken,
  getUserInformation,
  getAccessToken
} from '../services/TokenHandler';
import LoadingBarTop from '../components/barTop/barTop';

export const ProtectedRoutes = () => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const userInfo = getUserInformation();
  useEffect(() => {
    if (getAccessToken() && getAuthToken() && getRefreshToken() && userInfo) {
      setStatus(true);
    } else {
      setStatus(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      setStatus(false);
    };
  }, [userInfo]);

  if (loading) return <LoadingBarTop />;

  return status ? (
    pathname === '/' ? (
      <Navigate to="/dashboard" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};
