import { TOKEN_TO_VALIDATE } from '../utils/constantData';

export const getEmailAccessToken = () => {
  const token = localStorage.getItem(TOKEN_TO_VALIDATE) || '';
  return token;
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') || '';
};

export const getUserInformation = () => {
  return localStorage.getItem('user') || '';
};

export const getAuthToken = () => {
  return localStorage.getItem('idToken') || '';
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') || '';
};

export const setNewTokenID = (token) => {
  return localStorage.setItem('idToken', token) || '';
};
