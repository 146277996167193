import React from 'react';
import PropTypes from 'prop-types';
import { CSpinner } from '@coreui/react';
import './styles.scss';

const Buttons = ({
  id,
  type,
  className,
  onclick,
  children,
  disabled,
  loading,
  variant,
  ...other
}) => {
  return (
    <button
      id={id}
      type={type}
      className={`ci-button ${className} ${variant}`}
      onClick={onclick}
      disabled={disabled}
      {...other}
    >
      {loading && <CSpinner color="info" size="sm" />} {children}
    </button>
  );
};

Buttons.defaultProps = {
  onclick: () => {},
  id: '',
  type: 'button',
  className: '',
  disabled: false,
  loading: false,
  variant: 'primary'
};

Buttons.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onclick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary'])
};

export default Buttons;
