import React, { useState, useEffect } from 'react';
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react';
import AppBreadcrumb from '../breadcrumbs/AppBreadCrumb';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from '../dropDowns/Dropdown';
import { CDropdown, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import userPic from '../../assets/images/avatars/JB-profilePic.png';
import notificationIcon from '../../assets/images/svg/Notification.svg';
import HamburguerIcon from '../../assets/images/svg/HamburguerIcon.svg';
import { getUserInformation } from '../../services/TokenHandler';
import { useIVRHook } from '../../hooks/IVRHooks/useIVRHook';
import { ivrStatus, formatAccessNumber } from '../../utils/dataFormat/tableFormatData';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { AccountContext } from '../../services/AccountProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderBarNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useContext(AccountContext);
  const { methodCall } = useIVRHook();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const userInfo = JSON.parse(getUserInformation());
  const [notifications, setNotifications] = useState([]);
  const Counter = useRef(0);
  const Interval = useRef(null);

  const userOptions = [
    {
      linkName: t('menu.logout'),
      linkPath: '#'
    }
  ];
  useEffect(() => {
    getNotifications();
    document.addEventListener('visibilitychange', checkActivity);
    return () => {
      document.removeEventListener('visibilitychange', checkActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginOut = () => {
    logout();
    navigate('/login');
  };

  const checkActivity = function (event) {
    if (document.hidden) {
      Interval.current = setInterval(() => {
        Counter.current++;
        if (Counter.current === 300) {
          loginOut();
        }
      }, 1000);
    } else {
      clearInterval(Interval.current);
      Counter.current = 0;
    }
  };

  const queryParams = {
    page: 1,
    limit: 3,
    orderBy: '',
    orderDirection: '',
    searchBy: '',
    filterBy: ''
  };

  const getNotifications = async (params) => {
    const requestParams = params || queryParams;
    const { data, err } = await methodCall('getNodeAssigns', requestParams);
    if (!err) {
      if (data) setNotifications(data);
    }
  };

  const headerTogglerHandler = () => dispatch({ type: 'set', sidebarShow: !sidebarShow });

  return (
    <CHeader position="sticky" className="DashboardHeaderBar">
      <CContainer fluid>
        <div className="d-flex align-items-center">
          <CHeaderToggler className="ps-1 d-flex d-lg-none" onClick={headerTogglerHandler}>
            <img src={HamburguerIcon} alt="toggle side bar menu icon" />
          </CHeaderToggler>
          <CHeaderNav className="d-none d-md-flex me-auto DashboardHeaderBar-Title">
            <AppBreadcrumb />
          </CHeaderNav>
        </div>
        <div className="d-flex align-items-center">
          <CHeaderNav id="wrapNotifications">
            <CDropdown variant="nav-item">
              <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                <div className="NotificationIcon position-relative">
                  <img src={notificationIcon} alt="Notification Icon" />
                  <span className="position-absolute rounded-circle NotificationIcon-Dot">
                    <span className="visually-hidden">{t('menu.new-alerts')}</span>
                  </span>
                </div>
              </CDropdownToggle>
              <CDropdownMenu className="py-3" placement="bottom-end">
                {notifications.length > 0 && (
                  <>
                    <div className="h3">{t('menu.last-ivr-activity')}</div>
                    {notifications.map((n) => (
                      <Link to={'/ivr-config'} className="ItemNotification" key={n.id}>
                        {n.accessNumber && (
                          <span>{formatAccessNumber(n.accessNumber.accessNumber)}</span>
                        )}
                        {ivrStatus(n.status)}
                      </Link>
                    ))}
                  </>
                )}
              </CDropdownMenu>
            </CDropdown>

            {/* <Dropdown dropdownType="notification" list={notifications} image={notificationIcon} /> */}
          </CHeaderNav>
          <CHeaderNav>
            <Dropdown
              dropdownType="user"
              list={userOptions}
              image={userPic}
              userInitials={userInfo.imageText}
            />
          </CHeaderNav>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default HeaderBarNavigation;
