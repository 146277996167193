import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CFormInput } from '@coreui/react';
import eyeIcon from '../../assets/images/svg/passwordEye.svg';
import eyeSlashIcon from '../../assets/images/svg/passwordEyeSlash.svg';

const SecurityInput = ({ value, className, disabled, required, onChange }) => {
  const [securityFieldType, setSecurityFieldType] = useState(true);

  const showPasswordChangeHandler = () => setSecurityFieldType((prevState) => !prevState);

  return (
    <div className={`PasswordGroup ${className || ''}`}>
      <CFormInput
        type={securityFieldType ? 'password' : 'text'}
        required={required}
        placeholder="•••••••••••••••••••••••••••••••••"
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      <button
        id="eyeButtonIcon"
        type="button"
        className="PasswordGroup-eyeLink"
        onClick={showPasswordChangeHandler}
        disabled={disabled}
      >
        <img src={securityFieldType ? eyeIcon : eyeSlashIcon} alt="Eye Icon for password field." />
      </button>
    </div>
  );
};

SecurityInput.defaultProps = {
  className: '',
  disabled: false,
  required: false,
  value: '',
  onChange: () => {}
};

SecurityInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

export default SecurityInput;
