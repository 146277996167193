import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './i18n';

import { AccountProvider } from './services/AccountProvider';

import DefaultLayout from './layout/DefaultLayout';

import { ProtectedRoutes } from './navigation/ProtectedRoutes';

import NavigateOut from './navigation/NavigateOut';
import Spinner from './components/spinner/Spinner';

import './assets/scss/style.scss';
import LoadingBarTop from './components/barTop/barTop';

const TwoFactorAuthentication = lazy(() =>
  import('./views/pages/ExternalPages/TwoFactorAuthentication')
);
const RequestNewPassword = lazy(() => import('./views/pages/ExternalPages/RequestNewPassword'));
const ResetPassword = lazy(() => import('./views/pages/ExternalPages/ChangePassword'));
const CheckYourEmail = lazy(() => import('./views/pages/ExternalPages/CheckYourEmail'));
const Login = lazy(() => import('./views/pages/ExternalPages/Login'));

const SuccessfulPayment = lazy(() => import('./views/pages/ExternalPages/SuccessfulPayment'));
const FailedPayment = lazy(() => import('./views/pages/ExternalPages/FailedPayment'));

const App = () => {
  return (
    <div className="Cielo-bg">
      <AccountProvider>
        <Router>
          <Suspense fallback={<LoadingBarTop />}>
            <Routes>
              <Route path="/login" exact name="Login" element={<Login />} />
              <Route
                path="/2fa"
                exact
                name="2FA Verification"
                element={<TwoFactorAuthentication />}
              />
              <Route
                path="/requestnewpassword"
                exact
                name="RequestNewPassword"
                element={<RequestNewPassword />}
              />
              <Route path="/resetpassword" name="ResetPassword" element={<ResetPassword />} />
              <Route
                path="/checkyouremail"
                exact
                name="CheckYourEmail"
                element={<CheckYourEmail />}
              />

              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>

              <Route path="/payment/success/order/:order" element={<SuccessfulPayment />} />
              <Route path="/payment/failed/order/:type" element={<FailedPayment />} />
              <Route
                path="/buy/:stripeSession"
                element={<NavigateOut url={`${process.env.REACT_APP_CIELO_API_URL}/buy/`} />}
              />
            </Routes>
          </Suspense>
        </Router>
      </AccountProvider>
    </div>
  );
};

export default App;
