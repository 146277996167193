import React from 'react';
import Papa from 'papaparse';
import SecurityInput from '../../components/inputs/SecurityInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const accessNumberFormat = (value) => {
  return value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
};

export const booleanFormatter = (value) => {
  return !value ? 'No' : 'Yes';
};

export const fareType = (value) => {
  const badgeStyle =
    value.toLowerCase() === 'base' || value.toLowerCase() === 'bundle'
      ? 'BaseFareBadge'
      : 'AncillaryBadge';
  return <span className={badgeStyle}>{value}</span>;
};

export const ivrStatus = (value) => {
  const getStatusStyle = () => {
    if (value === 'Active') return 'ActiveStatus';
    if (value === 'Inactive') return 'DisabledStatus';
    return 'PendingStatus';
  };

  const badgeStyle = getStatusStyle();
  return <span className={badgeStyle}>{value}</span>;
};

export const ivrProductType = (value) => {
  const text = !!value ? 'FastLane' : 'CallPass';
  const badgeStyle = text === 'FastLane' ? 'FixedBadge' : 'DynamicBadge';
  return <span className={badgeStyle}>{text}</span>;
};

export const pricingType = (value) => {
  const badgeStyle = value === 'Fixed' ? 'FixedBadge' : 'DynamicBadge';
  return <span className={badgeStyle}>{value}</span>;
};

export const productType = (value) => {
  const badgeStyle = value === 'FastLane' ? 'FixedBadge' : 'DynamicBadge';
  return <span className={badgeStyle}>{value === 'PayPhone' ? 'CallPass' : value}</span>;
};

export const secretType = (value, id) => {
  return <SecurityInput className="TableSecretInput" value={value} />;
};

export const getExportFileBlob = ({ columns, data, fileType, fileName }) => {
  const headerNames = columns.map((col) => col.exportValue);
  const csvString = Papa.unparse({ fields: headerNames, data });
  return new Blob([csvString], { type: 'text/csv' });
};

export const formatAccessNumber = (accessNumber) => {
  const phoneNumber = parsePhoneNumber(`+${accessNumber}`);
  const formattedNumber =
    phoneNumber && phoneUtil.parseAndKeepRawInput(phoneNumber?.number, phoneNumber?.country);

  return !phoneNumber ? accessNumber : phoneUtil.format(formattedNumber, PNF.INTERNATIONAL);
};

export const productStatus = (value) => {
  const badgeStyle = value === 'Active' ? 'ActiveUser' : 'DisabledUser';
  const badgeValue = value === 'Active' ? 'Active' : 'Inactive';
  return <span className={badgeStyle}>{badgeValue}</span>;
};

export const userStatus = (value) => {
  const badgeStyle = value === 'Active' ? 'ActiveUser' : 'DisabledUser';
  const badgeValue = value === 'Active' ? 'Active' : 'Inactive';
  return <span className={badgeStyle}>{badgeValue}</span>;
};

export const voipStatus = (value) => {
  const badgeStyle = value ? 'ActiveStatus' : 'DisabledStatus';
  const badgeValue = value ? 'Enabled' : 'Disabled';
  return <span className={badgeStyle}>{badgeValue}</span>;
};
