import httpClient from '../../services/HttpClient';

export const useIVRHook = () => {
  const getNodeAssigns = async (dataObject) => {
    try {
      const { page, orderBy, orderDirection, searchBy, filterBy, limit } = dataObject;
      const orderByInfo = orderBy ? `&orderBy=${orderBy}&order=${orderDirection}` : '';
      const searchByInfo = searchBy ? `&search=${searchBy}` : '';
      const statusFilter = filterBy ? `&status=${filterBy}` : '';
      const limitInfo = limit ? `&limit=${limit}` : '';
      const pageNum = page || 1;

      const { data, pagination } = await httpClient({
        method: 'GET',
        url: `/node-assigns?page=${pageNum}${limitInfo}${orderByInfo}${searchByInfo}${statusFilter}`
      });
      return { data, pagination };
    } catch (err) {
      return { err };
    }
  };

  const editNode = async (id, dataObject) => {
    try {
      const { data } = await httpClient({
        method: 'PATCH',
        url: `/node-assigns/${id}`,
        data: dataObject
      });
      return { data };
    } catch (err) {
      return { err };
    }
  };

  const methodCall = (method, id = null, dataObject = {}) => {
    const calls = {
      getNodeAssigns,
      editNode
    };

    return (calls[method] || calls['get'])(id, dataObject);
  };

  return { methodCall };
};
