import { createStore } from 'redux';

let windowWidth = window.innerWidth;
let initialState = {
  sidebarShow: windowWidth > 768,
  breadcrumbs: [
    {
      name: 'Dashboard',
      link: null
    }
  ]
};

const ReportWindowSize = () => {
  windowWidth = window.innerWidth;
  initialState = {
    sidebarShow: windowWidth > 768,
    breadcrumbs: [
      {
        name: 'Dashboard',
        link: null
      }
    ]
  };
};

window.onresize = ReportWindowSize;

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
