import React from 'react';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';

import SVGRenderer from '../utils/svg/SVGRenderer';

import i18n from '../i18n';
import {
  CallFlow,
  Clock,
  DashboardI,
  Interconnection,
  Pricing,
  Products,
  Reports,
  Settings,
  Support,
  Vipp
} from '../utils/svg/iconSVG';

const navigationItems = [
  {
    component: CNavItem,
    name: i18n.t('menu.dashboard'),
    to: '/dashboard',
    icon: (
      <div className="nav-link__Icon">
        <DashboardI />
      </div>
    )
  },
  {
    component: CNavItem,
    name: i18n.t('menu.call-flow'),
    to: '/call-flow',
    icon: (
      <div className="nav-link__Icon">
        <CallFlow />
      </div>
    )
  },
  {
    component: CNavGroup,
    name: i18n.t('menu.pricing-rules'),
    to: '/pricing-rules',
    icon: (
      <div className="nav-link__Icon">
        <Pricing />
      </div>
    ),
    items: [
      {
        component: CNavItem,
        name: i18n.t('menu.callpass'),
        to: '/payphone',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="Payphone" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.fastlane'),
        to: '/fastlane',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="FastLane" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.vipp'),
        to: '/vipp',
        icon: (
          <div className="nav-link__Icon">
            <Vipp />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.vnta'),
        to: '/vnta',
        icon: (
          <div className="nav-link__Icon">
            <Clock />
          </div>
        )
      }
    ]
  },
  {
    component: CNavGroup,
    name: i18n.t('menu.reports'),
    to: '/reports',
    icon: (
      <div className="nav-link__Icon">
        <Reports />
      </div>
    ),
    items: [
      {
        component: CNavItem,
        name: i18n.t('menu.calls'),
        to: '/calls',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="FastLane" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.payments'),
        to: '/payments',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="FastLane" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.revenue'),
        to: '/revenue',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="Payphone" />
          </div>
        )
      }
    ]
  },
  {
    component: CNavTitle,
    name: i18n.t('menu.system')
  },
  {
    component: CNavItem,
    name: i18n.t('menu.products'),
    to: '/products',
    icon: (
      <div className="nav-link__Icon">
        <Products />
      </div>
    )
  },
  {
    component: CNavGroup,
    name: i18n.t('menu.settings'),
    to: '/settings',
    icon: (
      <div className="nav-link__Icon">
        <Settings />
      </div>
    ),
    items: [
      {
        component: CNavItem,
        name: i18n.t('menu.accounts'),
        to: '/accounts',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="Accounts" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.roles'),
        to: '/security',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="Security" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.users'),
        to: '/users',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="Users" />
          </div>
        )
      }
    ]
  },
  {
    component: CNavGroup,
    name: i18n.t('menu.interconnection'),
    to: '/interconnection',
    icon: (
      <div className="nav-link__Icon">
        <Interconnection />
      </div>
    ),
    items: [
      {
        component: CNavItem,
        name: i18n.t('menu.api'),
        to: '/api',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="API" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.voip'),
        to: '/voip',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="FastLane" />
          </div>
        )
      },
      {
        component: CNavItem,
        name: i18n.t('menu.ivr-configuration'),
        to: '/ivr-config',
        icon: (
          <div className="nav-link__Icon">
            <SVGRenderer iconName="IVR" />
          </div>
        )
      }
    ]
  },
  {
    component: CNavItem,
    name: i18n.t('menu.support'),
    to: '/support',
    icon: (
      <div className="nav-link__Icon">
        <Support />
      </div>
    )
  }
];

export default navigationItems;
