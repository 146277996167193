import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Spinner from '../components/spinner/Spinner';

// routes config
import privateRoutes from '../navigation/routes';
import { AccessNumberProvider } from '../services/AccessNumberProvider';
import { getUserInformation } from '../services/TokenHandler';
import { validatePermission, userPermissions } from '../utils/permissions/validatePermission';
import LoadingBarTop from '../components/barTop/barTop';

const AppContent = () => {
  const userInfo = getUserInformation();
  const {
    role: { name: nameRol }
  } = JSON.parse(userInfo);
  useEffect(() => {
    if (privateRoutes.length === 0) {
      renderPrivateRoutes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRoutes]);

  const permission = (permissionType) => {
    const defaultPermission = +true;
    const permissionName = {
      VIPO: defaultPermission,
      Ventana: defaultPermission,
      Dashboard: defaultPermission,
      'Access Numbers': +validatePermission(userPermissions('/access-number', 'get'), userInfo),
      FastLane: +validatePermission(userPermissions('/pricing-rule', 'get'), userInfo),
      CallPass: +validatePermission(userPermissions('/payphone', 'get'), userInfo),
      Vipp: +validatePermission(userPermissions('/vipp', 'get'), userInfo),
      Vnta: +validatePermission(userPermissions('/vnta', 'get'), userInfo),
      Reports:
        +validatePermission(userPermissions('/reports/payments', 'get'), userInfo) &&
        +validatePermission(userPermissions('/reports/revenues', 'get'), userInfo) &&
        +validatePermission(userPermissions('/reports/calls', 'get'), userInfo),

      Products: +(
        validatePermission(userPermissions('/company/products', 'post'), userInfo) &&
        nameRol === 'SUPER-ADMIN'
      ),

      Accounts: +(
        validatePermission(userPermissions('/company', 'get'), userInfo) &&
        nameRol === 'SUPER-ADMIN'
      ),

      Users: +validatePermission(userPermissions('/user', 'get'), userInfo),
      Roles: +validatePermission(userPermissions('/roles', 'get'), userInfo), //99999
      API: +validatePermission(userPermissions('/api-connections', 'get'), userInfo),
      VoIP: +validatePermission(userPermissions('/trunks', 'get'), userInfo),
      'IVR Configuration': +validatePermission(userPermissions('/node-assigns', 'get'), userInfo),
      Support: defaultPermission,
      'Page Not Found': defaultPermission
    };

    return permissionName[permissionType];
  };

  const addPermissions = privateRoutes.map((route) => ({
    ...route,
    permission: permission(route.name)
  }));

  const renderPrivateRoutes = () => {
    if (addPermissions) {
      return addPermissions
        .filter((route) => route.permission === 1)
        .map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        });
    }

    return <></>;
  };

  return (
    <AccessNumberProvider>
      <Suspense fallback={<LoadingBarTop />}>
        <Routes>
          {privateRoutes && renderPrivateRoutes()}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </AccessNumberProvider>
  );
};

export default React.memo(AppContent);
