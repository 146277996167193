import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getEmailAccessToken, getAuthToken, getRefreshToken, setNewTokenID } from '../TokenHandler';

const axiosMainInstance = axios.create();
const axiosRefreshInstance = axios.create();

const refreshAuth = async (failedRequest) => {
  if (failedRequest.config.url.includes('/auth/validate-jwt')) {
    return Promise.resolve();
  }
  try {
    const tokenRefreshResponse = await axiosRefreshInstance.post(
      `${process.env.REACT_APP_CIELO_API_URL}auth/refresh-token`,
      {
        email: localStorage.getItem('userEmail'),
        refreshToken: getRefreshToken()
      }
    );

    if (tokenRefreshResponse && tokenRefreshResponse.data) {
      const token = tokenRefreshResponse.data.idToken.jwtToken;

      failedRequest.config.headers['Authorization'] = `Bearer ${token}`;

      setNewTokenID(token);

      return Promise.resolve();
    } else {
      localStorage.clear();
      return Promise.reject(new Error('Error refreshing token'));
    }
  } catch (error) {
    localStorage.clear();
    return Promise.reject(error);
  }
};

createAuthRefreshInterceptor(axiosMainInstance, refreshAuth);

axiosMainInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken() || getEmailAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const httpClient = (config = {}) => {
  const axiosConfig = {
    ...config,
    baseURL: config.baseURL || process.env.REACT_APP_CIELO_API_URL,
    headers: {
      ...config.headers
    }
  };

  return axiosMainInstance(axiosConfig)
    .then((resp) => {
      const { data, pagination, info } = resp.data;

      if (!pagination) {
        return resp;
      } else {
        return { data, pagination, info };
      }
    })
    .catch(async (error) => {
      console.log('error', error);
      const { response, message } = error || {};

      if (response) {
        const { status, data } = response;

        if (data.error) {
          throw data;
        }

        if (status === 400 || status === 403) {
          throw data;
        }

        if (status) {
          throw data.detail || data.message || message;
        }
      }

      throw message;
    });
};

export default httpClient;
