import React from 'react';
import PropTypes from 'prop-types';
import { cieloSVGS } from './cieloSVGS';

const SVGRenderer = ({ iconName, className, title, color }) => {
  const iconFiltered = cieloSVGS.filter((icon) => icon.iconName === iconName);

  return (
    <svg
      className={className}
      width={iconFiltered[0].iconProperties.width}
      height={iconFiltered[0].iconProperties.height}
      viewBox={iconFiltered[0].iconProperties.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      title={title}
      fill={color}
    >
      <path d={iconFiltered[0].iconProperties.d} />
    </svg>
  );
};

SVGRenderer.defaultProps = {
  className: ''
};

SVGRenderer.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string
};

export default SVGRenderer;
